import React, { useEffect, useContext } from "react"

import { categories } from "../../static/assets/js/categories"
import LayoutContext from "../components/layout/layout.context"
import HistoriesContext from "../context/histories.context"
import useHistoriesData from "../hooks/useHistoriesData"
import Home from "../components/template/home/Home"
import CategoryHeader from "../components/modules/categoryHeader/CategoryHeader"

const Aventura = () => {
  const context = useContext(LayoutContext)
  const historiesData = useHistoriesData()
  const { aventura } = categories

  useEffect(() => {
    context.setActiveCategory(5)
    return () => {
      context.setActiveCategory(undefined)
    }
  }, [])

  return (
    <HistoriesContext.Provider value={historiesData}>
      <div className="wrapper">
        <CategoryHeader title={aventura.title} desc={aventura.desc} />
        <Home category="aventura" hideHeader />
      </div>
    </HistoriesContext.Provider>
  )
}

export default Aventura
